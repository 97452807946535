<template>
  <div>
    <b-card no-body :style="'color:' + d_menuData.style.color + '; background-color: ' + d_menuData.style.backgroundColor">
      <w-top-menu :p_menuData="d_menuData"></w-top-menu>
      <b-row>
        <b-col cols="12">
          <template v-if="d_totalCount > d_tablePagination['perpage']">
            <b-pagination size="sm" v-model="d_tablePagination['current']" :total-rows="d_totalCount" :per-page="d_tablePagination['perpage']" aria-controls="log-table"></b-pagination>
          </template>
          <div id="case_table" :style="f_dataSetStyle(300)" tabindex="0">
            <w-table key="data_table" :p_tablePagination="d_tablePagination" :p_dataset="d_dataList" :p_columnList="d_columnList" :f_calculateRowNoStyle="f_calculateRowNoStyle" :f_calculateStyleDatasetTable="f_calculateStyleDatasetTable" :f_selectCell="f_selectCell" :f_dblClickToCell="f_goToUserEdit"></w-table>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-modal v-if="d_showSearchSettings" v-model="d_showSearchSettings" size="md" title="Arama Ayarları" centered hide-footer header-bg-variant="primary">
      <!-- <b-row>
        <b-col cols="12" v-if="user.permissions_result && user.permissions_result.indexOf('hospital-settings_all-hospitals') !== -1">
          <b-form-select v-model="d_hospitalListType">
            <option value="all_hospitals">Tüm Hastaneler</option>
            <option value="selected_hospital">Seçilen Hastane</option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" v-if="d_hospitalListType === 'selected_hospital'">
          <b-form-select :plain="true" v-model="d_selectedHospitalGroup" @change="f_changeHospitalGroup()" required>
            <option v-for="hospital_groups in d_hospitalGroupList" :value="hospital_groups.value">{{ hospital_groups.label }}</option>
          </b-form-select>
        </b-col>
        <b-col cols="6" v-if="d_hospitalListType === 'selected_hospital' && d_selectedHospitalGroup">
          <b-form-select :plain="true" v-model="d_selectedHospitalId" @change="f_changeHospitalId()" required>
            <option v-for="hospitals in d_hospitalList" :value="hospitals.value">{{ hospitals.label }}</option>
          </b-form-select>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col cols="12">
          <b-form-checkbox switch id="d_showPassiveUsers" v-model="d_showPassiveUsers" name="d_showPassiveUsers" value="yes" unchecked-value="no">
            Pasif kullanıcıları da listele
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_showTextFilter" v-model="d_showTextFilter" size="md" title="Arama İfadesi" centered hide-footer header-bg-variant="primary">
      <b-row>
        <b-col cols="4">
          Arama İfadesi
        </b-col>
        <b-col cols="8">
          <b-input type="text" id="d_searchText" placeholder="Arama İfadesi Giriniz" v-model="d_searchText"></b-input>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  default as router
} from '@/router';
import { mapGetters } from 'vuex';
import UserService from '@/services/users';
import store from '@/store';
import moment from 'moment';
import {
  default as WTopMenu
} from '@/components/widgets/WTopMenu';
import {
  default as WTable
} from '@/components/widgets/WTable';
// import {
//   default as Modal
// } from '@/components/widgets/Modal';

export default {
  name: 'UserList',
  components: {
    WTable,
    WTopMenu
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      device: 'device'
    })
  },
  data () {
    return {
      d_showSearchSettings: false,
      d_showTextFilter: false,
      d_menuData: {
        'selected_menu': 'general', // your defined menu value below
        'style': {
          'backgroundColor': '#FFFFFF',
          'subBackgroundColor': '#FFFFFF',
          'subColor': '#000000',
          'color': '#000000',
          'main_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#000000'
          },
          'tab_header': {
            'backgroundColor': '#FFFFFF',
            'color': '#878484'
          }
        },
        'list': [{
          'label': 'Genel',
          'icon': require('@/icon/2979152.png'),
          'value': 'general',
          'list': [{
            'icon': '',
            'label': 'Genel',
            'value': 'general',
            'list': [{
              'type': 'button', // button, buttongroup, color
              'title': 'Ayarlar',
              'icon': require('@/icon/1584256.png'),
              'label': '',
              'value': 'general',
              'click': this.f_showSearchSettings
            }, {
              'type': 'button', // button, buttongroup, color
              'title': 'Filtreleme',
              'icon': require('@/icon/2447096.png'),
              'label': '',
              'value': 'wdmnamelanguage',
              'click': this.f_showTextFilter
            }]
          }, {
            'icon': '',
            'label': 'Güncelle',
            'value': 'refresh',
            'list': [{
              'type': 'button', // button, buttongroup, color
              'title': 'Verileri Güncelle', // save
              'icon': require('@/icon/615577.png'),
              'label': '',
              'value': 'f_getLogs',
              'click': this.f_getLogs
            }]
          }]
        }, {
          'label': 'Yardım',
          'icon': require('@/icon/2249810.png'),
          'value': 'help',
          'list': [{
            'icon': require('@/icon/2249810.png'),
            'label': 'Genel',
            'value': 'general',
            'list': []
          }]
        }]
      },
      d_tablePagination: { 'current': 1, 'perpage': 100 },
      d_columnList: [{
        value: "username",
        label: "Kullanıcı Adı"
      }, {
        value: "name",
        label: "Ad-Soyad"
      }, {
        value: "hospital_id",
        label: "Hastane"
      }, {
        value: "is_active",
        label: "Aktif mi?"
      }, {
        value: "is_email_approved",
        label: "Email onayı"
      }],
      d_searchText: '',
      user: {},
      d_selectedLogTypes: ['error', 'information'],
      d_showPassiveUsers: 'no',
      d_showMode: true,
      d_totalCount: 0,
      d_dataList: [],
      d_selectedCells: [],
      d_hospitalListType: 'all_hospitals',
    }
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {
    this.f_getLogs();
  },
  destroyed () {},
  methods: {
    f_goToUserEdit: function (row, col_value) {
      router.push({ path: '/useredit', query: { 'username': row.username, 'edit': true } });
    },
    f_selectCell: function (row_ind, col_ind, type) {
      if (type === 'cell') {
        let select = row_ind.toString() + '-' + col_ind.toString();
        if (this.d_selectedCells.indexOf(select) === -1) {
          this.d_selectedCells.push(select);
        } else {
          this.d_selectedCells.splice(this.d_selectedCells.indexOf(select), 1);
        }
      } else if (type === 'row') {
        for (let c in this.d_columnList) {
          let y = row_ind.toString() + '-' + c.toString();
          this.d_selectedCells.push(y);
        }
      } else if (type === 'col') {
        for (let r in this.d_dataList) {
          let y = r.toString() + '-' + col_ind.toString();
          this.d_selectedCells.push(y);
        }
      }
      this.$forceUpdate();
    },
    f_dataSetStyle: function (x) {
      let height = window.innerHeight - x;
      let overflow_x = 'auto';
      return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x:' + overflow_x + '; margin-bottom: 0px; border: none;';
    },
    f_calculateStyleDatasetTable: function (row_ind, col_ind) {
      let style = '';
      style = 'white-space: nowrap; border-right: solid 1px #c1c7cc; width: 150px;';
      if (this.d_selectedCells.indexOf(row_ind.toString() + '-' + col_ind.toString()) !== -1) {
        style += 'background-color: #c6fcd8;';
      } else {
        style += 'background-color: white;';
      }
      return style;
    },
    f_calculateRowNoStyle: function (row_ind) {
      let style = '';
      let w = '60px';
      style = 'position: sticky; left: 0; width: ' + w + '!important; border-right: solid 1px #c3d8d8; cursor: pointer; background-color: white; font-size: 10px; color: black';
      return style;
    },
    f_showSearchSettings: function () {
      this.d_showSearchSettings = true;
    },
    f_showTextFilter: function () {
      this.d_showTextFilter = true;
    },
    f_getLogs: function (keep_page = false) {
      let service_data = {
        'pagination': this.d_tablePagination,
        'search_text': this.d_searchText,
        'include_passive': this.d_showPassiveUsers
      }
      this.d_selectedCells = [];
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kullanıcılar hazırlanıyor.' } });
      if (!keep_page) {
        this.d_tablePagination['current'] = 1;
      }
      this.d_totalCount = 0;
      UserService.getUsersForManager(service_data)
        .then(resp => {
          this.d_showMode = false;
          if (resp.data.status === 'success') {
            this.d_dataList = resp.data.result.user_list;
            this.d_totalCount = resp.data.result.user_count;
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            alert('error', resp.data.message);
          }
        });
    }
  },
  watch: {
    'd_searchText': function () {
      let text = this.d_searchText;
      setTimeout(function () {
        if (text === this.d_searchText) {
          this.f_getLogs();
        }
      }.bind(this), 2000);
    },
    'd_tablePagination.current': function () {
      this.f_getLogs(true);
    }
  }
}

</script>

